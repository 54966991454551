<script lang="ts" setup></script>

<template>
  <div class="centered-skeleton">
    <v-skeleton h="600px" width="700px" radius="32px" />
  </div>
</template>

<style lang="scss" scoped>
  .centered-skeleton {
    max-width: 900px;
    display: flex;
    margin: auto;
    padding: 0 24px;
  }
</style>
